import { Component, Input, OnInit } from '@angular/core';
import { SurveySample } from '../../../survey-samples/models/survey-sample.model';
import { AssessmentTypeEnum } from "../../enums/survey-samples.enum";

@Component({
  selector: 'app-sample-number',
  templateUrl: './sample-number.component.html',
  styleUrls: ['./sample-number.component.css'],
})
export class SampleNumberComponent implements OnInit {
  @Input() sample: SurveySample;
  public assessmentTypeEnum = AssessmentTypeEnum;

  constructor() { }

  ngOnInit() {}

}
